

export function getURL(url: string) {
    return getBase() + url;
}

export function getBase(): string {
    const base = (window as any).NGR_BASE_CONFIG.basePath as string;
    return base ? base : "/"
}
