import * as React from 'react';
import Machinata from "machinata-reactjs";

export const BrandingProvider = (props: { branding: string, children: React.ReactNode }) => {
  React.useEffect(
    () => {
      const styleId: string = "styleid";
      const font_resourcesStyleId: string = "min_styleid";
      const styleEl = document.getElementById(styleId);
      if (styleEl) {
        document.head.removeChild(styleEl);
      }
      if (props.branding === "csam") {
        //TODO: load csam css
        const font_resources_link = document.createElement('link');
        font_resources_link.id = font_resourcesStyleId;
        font_resources_link.rel = "stylesheet";
        font_resources_link.href = "./csam/csam-fonts.css";
        document.head.appendChild(font_resources_link);

        const link = document.createElement('link');
        link.id = styleId;
        link.rel = "stylesheet";
        link.href = "./assets/csam/css/machinata-csam-reporting-bundle.min.css";
        document.head.appendChild(link);
        Machinata.Reporting.Branding.loadBrandingConfiguration("csam");
      } else if (props.branding === "cinnamon") {
        //TODO: load cinnamon css
        const font_resources_link = document.createElement('link');
        font_resources_link.id = font_resourcesStyleId;
        font_resources_link.rel = "stylesheet";
        font_resources_link.href = "./cinnamon/cinnamon-fonts.css";
        document.head.appendChild(font_resources_link);

        const link = document.createElement('link');
        link.id = styleId;
        link.rel = "stylesheet";
        link.href = "./static/file/assets/cinnamon/css/machinata-cinnamon-reporting-bundle.min.css";
        document.head.appendChild(link);
        Machinata.Reporting.Branding.loadBrandingConfiguration("cinnamon");
      } else if (props.branding === "pcp") {
        const font_resources_link = document.createElement('link');
        font_resources_link.id = font_resourcesStyleId;
        font_resources_link.rel = "stylesheet";
        font_resources_link.href = "./csam/csam-fonts.css";
        document.head.appendChild(font_resources_link);

        const link = document.createElement('link');
        link.id = styleId;
        link.rel = "stylesheet";
        link.href = "./assets/pcp/css/machinata-pcp-reporting-bundle.min.css";
        document.head.appendChild(link);
        Machinata.Reporting.Branding.loadBrandingConfiguration("pcp");
      }
      //eslint-disable-next-line
    }, [props.branding]
  );
  return <>
    {props.children}
  </>
}