import * as React from 'react';
import * as cinnamonMachinataSource from "machinata-cinnamon";
import * as csamMachinataSource from "machinata-csam";
import * as pcpMachinataSource from "machinata-pcp";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { getURL } from 'src/lib/Util';
import { BrandingProvider } from './BrandingProvider';
import { ReportViewer } from './ReportViewer';


export function App() {
  React.useEffect(
    () => {
      csamMachinataSource.init();
      cinnamonMachinataSource.init();
      pcpMachinataSource.init();
    }, []
  );
  const defaultBranding = (window as any).NGR_BASE_CONFIG.defaultBranding as string;
  return <Router>
    <Switch>
      <Route exact path={getURL("")}>
        <BrandingProvider branding={defaultBranding}>
          <ReportViewer branding={defaultBranding} />
        </BrandingProvider>
      </Route>
      <Route path={getURL("csam")}>
        <BrandingProvider branding={"csam"}>
          <ReportViewer branding="csam" />
        </BrandingProvider>
      </Route>
      <Route path={getURL("cinnamon")}>
        <BrandingProvider branding={"cinnamon"}>
          <ReportViewer branding="cinnamon" />
        </BrandingProvider>
      </Route>
      <Route path={getURL("pcp")}>
        <BrandingProvider branding={"pcp"}>
          <ReportViewer branding="pcp" />
        </BrandingProvider>
      </Route>
    </Switch>
  </Router>;
}






