import Machinata from "machinata-reactjs";
import { FullReport } from "./Report";

export function renderReport(branding: string, report: FullReport) {
    const assetsPath = branding === "cinnamon" ? `/static/file/assets/cinnamon` : `/assets/${branding}/`
    const defaultConfig = {
        "brandingConfiguration": branding,
        "dataProvider": "Memory",                           // The set data provider for retrieving any data items, should be 'JSON'.
        "catalogSource": null,                              // The source of the catalog for this user. If set to 'auto', the catalog source is derived from the URL parameter 'catalog'. If the provider is JSON, this can point to any CORS accessible JSON file.
        "dataSource": report.data,                           // The source of the report contents/data. If set to 'auto', the catalog and the UI controls the report data, or if there is no catalog then the query param 'data' is used. If the provider is JSON, this can point to any CORS accessible JSON file.
        "contentSelector": "#totallySafeId",                        // Make sure the catalog page points to the correct url
        "performChapterChangesInline": true,
        "reportWindowTitle": null,
        "assetsPath": assetsPath
    };
    const chaptersConfig = {
        "chapters": "auto",
        "initialChapter": 1
    }
    if (report.chapterTitles.length > 0) {
        console.log("if")
        return Machinata.Reporting.init({...defaultConfig, ...chaptersConfig});
    } else {
        console.log("else")
        return Machinata.Reporting.init(defaultConfig);
    }
}