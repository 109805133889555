import * as React from 'react';
import { FullReport } from 'src/lib/Report';

export const FileChoser = (props: {
    onReceive: (f: FullReport) => void,
    branding: string,
    label: string,
    title?: string;
  }) => {
    return <>
      {props.title && <div style={{ padding: "20px" }}><h1 className={props.branding + "-title"}>{props.title}</h1></div>}
      <input id="actual-btn" type="file" accept=".json" onChange={
        (e: React.ChangeEvent<HTMLInputElement>) => {
          const reader = new FileReader();
          reader.addEventListener('load', (event: ProgressEvent<FileReader>) => {
            if (event.target) {
              let t: any = JSON.parse(event.target.result as string);
              props.onReceive(new FullReport(
                t
              ));
            }
          });
          if (e.target.files && e.target.files.length > 0)
            reader.readAsText(e.target.files![0]);
        }
      } hidden />
      <label htmlFor="actual-btn" className={props.branding + "-label"}>{props.label}</label>
    </>;
  };