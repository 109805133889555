/** @internalapi
 * Contains the full information of an abstract report, extracts only the properties
 * currently required.
 */
export class FullReport {
    private titlesToIds: Map<string, string> = new Map<string, string>();
    private titles: string[] = [];

    constructor(public readonly data: any) {
        if (this.data.body && this.data.body.children)
            for (let i = 0; i < this.data.body.children.length - 1; i++) {
                const child = this.data.body.children[i];
                if (child.mainTitle) {
                    this.titles.push(child.mainTitle.resolved);
                    this.titlesToIds.set(child.mainTitle.resolved, child.id);
                }
            }
    }

    public get chapterTitles(): string[] {
        return this.titles;
    }

    public getChapterIdFromTitle(title: string): string | undefined {
        if (this.titlesToIds.has(title)) {
            return this.titlesToIds.get(title);
        }
        return undefined;
    }

    public getChapterTitleFromId(id: string) {
        let title: string | undefined = undefined;
        this.titlesToIds.forEach(
            (val: string, key: string) => {
                if (val === id)
                    title = key;
            }
        );
        return title;
    }

    public title(language?: string): string {
        const safeLanguage = language ? language : "*";
        const title = this.data.reportTitle;
        if (title && title.translations) {
            const trans = title.translations;
            if (trans[safeLanguage]) {
                return trans[safeLanguage];
            } else if (trans["*"]) {
                return trans["*"];
            }
        } else if (title.resolved) {
            return title.resolved
        }
        return ""
    }

}
