import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { FullReport } from 'src/lib/Report';
import Machinata from "machinata-reactjs";
import axios, { AxiosResponse } from "axios";
import { renderReport } from 'src/lib/Reporting';
import { FileChoser } from './FileChoser';
import { ChapterNavigation } from './ChapterNavigation';

export const ReportViewer = (props: {
  branding: string
}) => {
  const [report, setReport] = React.useState<FullReport | undefined>(undefined);
  const [chapter, setChapter] = React.useState<string | undefined>(undefined);
  const [instance, setInstance] = React.useState<any>(undefined);

  const location = useLocation();
  const history = useHistory();

  const config = (window as any).NGR_BASE_CONFIG;
  const showChoser = config.showChoser as boolean;

  //if executionId provided in path parameter, extract that
  const els = location.pathname.split("/");
  const search = location.search;
  const executionId = els[els.length - 1] === props.branding ? undefined : els[els.length - 1];

  //if datasource provided in url parameter, extract that
  let data: string | undefined = undefined
  if (search.length > 0) {
    search.split("?")[1].split("&").forEach(el => {
      if (el.indexOf("data=") >= 0) {
        data = el.split("data=")[1];
      }
    })
  }

  /**set ressourceUrl with following priorities
    1. If datasource provided in url parameter, take that one
    2. If executionId provided as path parameter, take that one
    3. Don't have a report to order at the moment
  */
  const ressourceUrl = data ? data : executionId ? `/source-files/${executionId}.json` : undefined;
  Machinata.Reporting.Handler.printNode = undefined;
  React.useEffect(
    () => {
      if (ressourceUrl) {
        axios.get(ressourceUrl).then(
          (res: AxiosResponse) => {
            const fr = new FullReport(res.data);
            if (fr.chapterTitles.length > 0)
              setChapter(fr.chapterTitles[0]);
            setReport(fr);
          },
          (res: AxiosResponse) => {
            history.push(els.slice(0, els.length - 1).join("/"))
            console.error("Report not found. Redirected to report choser.");
          }
        );
        setInstance(undefined);
      }
      //eslint-disable-next-line
    }, [ressourceUrl]
  )


  React.useEffect(
    () => {
      if (report) {
        setInstance(renderReport(props.branding, report));
      }
      //eslint-disable-next-line
    }, [report]
  );

  const changeChapter = (s: string) => {
    setChapter(s);
    Machinata.Reporting.Tools.changeChapterViaInlineUpdate(instance, report!.getChapterIdFromTitle(s));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  if (!report) {
    if (showChoser && !executionId) {
      return <FileChoser
        branding={props.branding}
        label="Chose Report to display"
        title="Welcome to Cinnamon Report Viewer"
        onReceive={(fr: FullReport) => {
          if (fr.chapterTitles.length > 0)
            setChapter(fr.chapterTitles[0]);
          setReport(fr);
        }}
      />;
    } else {
      return null;
    }
  }
  document.title = config.useReportTitle as boolean ? report.title() : config.title as string;
  return (
    <>
      <div className="floater">
        {showChoser && !executionId &&
          <FileChoser
            branding={props.branding}
            label="Change report"
            onReceive={(fr: FullReport) => {
              if (fr.chapterTitles.length > 0)
                setChapter(fr.chapterTitles[0]);
              setReport(fr);
            }}
          />}
      </div>
      <div className="container">
        {
          report && report.chapterTitles.length > 0 && (
              instance && <ChapterNavigation chapters={report.chapterTitles} chapterToDisplay={chapter} setChapterToDisplay={changeChapter} branding={props.branding} />
          )
        }
        <div id="totallySafeId"></div>
        {
          report && report.chapterTitles.length > 0 && (
              instance && <ChapterNavigation chapters={report.chapterTitles} chapterToDisplay={chapter} setChapterToDisplay={changeChapter} branding={props.branding} />
          )
        }
      </div>
    </>
  );
};