import * as React from 'react';

export const ChapterNavigation = (props: { chapters: string[]; chapterToDisplay?: string; setChapterToDisplay: (s: string) => void; branding: string; }) => {
    let chapters = props.chapters.map(
        (chap: string) =>
            <li
                className={chap === props.chapterToDisplay ? "selected-chapter" : undefined}
                key={chap}
                onClick={() => { props.setChapterToDisplay(chap); }}
                style={{ width: `${100 / props.chapters.length}%` }}
            >
                {chap}
            </li>);
    return (
        <div className={props.branding + "-portal chapterNav"}>
            <ul
                className={props.branding + "-portal chapterSelect"}>
                {chapters}
            </ul>
        </div>
    );
};